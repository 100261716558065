@tailwind base;
@tailwind components;
@tailwind utilities;
:root {
  --bg-color: rgb(230, 178, 178);
  --text-color: #fff;
  --color-1: #f8f8f8;
  --color-2: #0d0d0d;
  --header-height: 80px;
  --icon-height: 16px;
  --icon-translate: 0%;
  --arrow-width: 2px;
  --primary: #f1f1f1;
  --secondary: rgba(100, 100, 100, 0.4);
  --bg-color: #0a0a0a;
  --bg-project: transparent;
  --primary: transparent;
  --secondary: rgba(100, 100, 100, 0.4);
  --tertiary: #f1f1f1;
}
#gradient-canvas {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  --gradient-color-1: #b90e50;
  --gradient-color-2: #032e46;
  --gradient-color-3: #151525;
  --gradient-color-4: #0f3b5e;
}

text {
  fill: white;
  font-size: 20px;
}
/* Let's get this party started */

html,
#__next {
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  text-rendering: optimizeLegibility;
  -webkit-tap-highlight-color: transparent;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  height: -webkit-fill-available;
}

body {
  @apply bg-neutral-950 text-slate-700;
  color: var(--text-color);
  position: relative;
  overflow-x: hidden;
  min-height: 100dvh;
  height: 100%;
  width: 100%;
  height: -webkit-fill-available;
  overflow: overlay;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}
a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  scrollbar-width: thin;
  scrollbar-color: var(--secondary) var(--primary);
}
@media (max-width: 550px) {
  p {
    font-size: 16px;
  }
}
#dom:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @apply bg-neutral-950;
}
// Lenis scroll must be include
html.lenis {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.underline {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  border-radius: 1.5rem;
  background: #fff;
}
.about.active {
  color: #0d0d0d;
}

#canvas {
  canvas {
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
  }
}

.canvas {
  height: calc(100dvh - 60px) !important;
  @media screen and (max-width: 1200px) {
    height: calc(100dvh - 0px) !important;
  }
}

.canvas:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
#circle text {
  white-space: pre-wrap;
  letter-spacing: 0.15rem;
}
.screen-container {
  position: relative;
  overflow: hidden;
  @apply bg-neutral-950;
  &:after {
    content: '';
    z-index: 0;
    position: absolute;
    top: 0;
    left: 50%;
    height: 100vw;
    width: 200vw;
    transition: background 1s ease-in-out;
    transform: translateX(-50%) translateY(50vh);
    opacity: 0.5;
  }
}

.view-works {
  position: fixed !important;
  bottom: 50px !important;
  left: calc(50% - 50px) !important;
  transform: translate(calc(-50% + 60px), 100%) !important;
  width: 100px;
}

.introLine {
  display: flex;
  font-size: clamp(1rem, 3vw, 6rem);
  text-transform: uppercase;
  @apply relative z-20 mt-6 font-bold leading-tight text-orange-400;
}

.introLine a {
  margin: 0px;
}

.introLine:nth-of-type(5),
.introLine:nth-of-type(6) {
  display: inline-flex;
}

.introLine:nth-of-type(6) {
  right: -40px;
  position: relative;
}

.introLine a {
  margin: 0px;
}

.image-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.noise {
  --dot-bg: black;
  --dot-color: white;
  --dot-size: 2px;
  --dot-space: 22px;
  background: linear-gradient(
        90deg,
        var(--dot-bg) calc(var(--dot-space) - var(--dot-size)),
        transparent 1%
      )
      center / var(--dot-space) var(--dot-space),
    linear-gradient(
        var(--dot-bg) calc(var(--dot-space) - var(--dot-size)),
        transparent 1%
      )
      center / var(--dot-space) var(--dot-space),
    var(--dot-color);
  width: calc(100vw - 8rem);
  height: calc(100dvh - 60px);
  transform: translateX(-50%);
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  z-index: -1;
  @media screen and (max-width: 1200px) {
    width: 100vw !important;
    height: 100dvh;
  }
}

// /* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 9px;
}

*::-webkit-scrollbar-track {
  background: var(--primary);
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--secondary);
  border-radius: 5px;
  border: 3px solid var(--primary);
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--tertiary);
}
